import { graphql } from 'gatsby'
import * as React from "react"
import {} from "gatsby"
import Layout from "../components/layout"
import { createGlobalStyle } from "styled-components"
import Seo from "../components/seo"
import Image from "../components/image"

// CSSモジュール
import * as ttPage from "../components/timetable.module.css"
import * as style from "../components/index.module.css"

const GlobalStyle = createGlobalStyle`
#gatsby-focus-wrapper {background-color:white;}
`

export const query = graphql`
query {
  site: site {
    id
    siteMetadata {
      author
      backgroundColor
      description
      domain
      shortName
      siteLanguage
      title
      url
    }
  }

  top: allMarkdownRemark(filter: {frontmatter: {page: {eq: "top"}}}) {
      edges {
          node {
              fileAbsolutePath
              frontmatter {
                description
                eventEndDay
                eventEndMonth
                eventEndTime
                eventEndWeek
                eventStartDay
                eventStartMonth
                eventStartTime
                eventStartWeek
                eventYear
                order
                organizer
                page
                price
                title
                venue
                subscriptionURL
              }
              html
          }
      }
  }
  leaf: allMarkdownRemark(
    filter: {frontmatter: {page: {in: ["timetable"]}}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
        node {
            fileAbsolutePath
            frontmatter {
              page
              title
              description
              order
            }
            html
            rawMarkdownBody
        }
    }
  }
  speakers: allSpeakers202110Yaml(sort: {fields: order, order: ASC}) {
    edges {
      node {
        message
        name
        order
        profileText
        url
        image
        id
        copyText
      }
    }
  }
  timetable: allTimetable202110Yaml(sort: {fields: start, order: ASC}) {
    edges {
      node {
        id
        start
        end
        titleEn
        titleJp
        speakers
      }
    }
  }
}
`

const IndexPage = (props) => {
  const { site } = props.data;
  const { top } = props.data;
  const { leaf } = props.data;
  const { timetable } = props.data;
  const { speakers } = props.data;

  const topData = top.edges[0].node
  let isEndDate = (topData.frontmatter.eventEndMonth) ? true : false

  const leafDatas = leaf.edges.map(({ node }) => {
    return node
  })

  const timetableDatas = timetable.edges.map(({ node }) => {
    return node
  })

  const speakerDatas = speakers.edges.map(({ node }) => {
    return node
  })

  var startDate = `${topData.frontmatter.eventYear}/${topData.frontmatter.eventStartMonth}/${topData.frontmatter.eventStartDay} ${topData.frontmatter.eventStartTime}`
  var endDate = `${topData.frontmatter.eventYear}/${topData.frontmatter.eventStartMonth}/${topData.frontmatter.eventStartDay} ${topData.frontmatter.eventEndTime}`

  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "Event",
      name: `${topData.frontmatter.title}`,
      startDate: `${startDate}`,
      endDate: `${endDate}`,
      eventStatus: "https://schema.org/EventScheduled",
      eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
      location: {
        "@type": "VirtualLocation",
        url: `${site.siteMetadata.url}`,
      },
      image: {
        "@type": "ImageObject",
        url: `${site.siteMetadata.ogpImage}`,
      },
      description: `${topData.frontmatter.description}`,
      offers: {
        "@type": "Offer",
        url: `${topData.frontmatter.subscriptionURL}`,
        price: "0",
        priceCurrency: "JPY",
        availability: "https://schema.org/InStock",
      },
      performer: {
        "@type": "PerformingGroup",
        name: `${topData.frontmatter.organizer}`
      },
      organizer: {
        "@type": "Organization",
        name: `${topData.frontmatter.organizer}`,
        url: `${site.siteMetadata.url}`,
      }
    },
  ]

  return (
  <Layout title={leafDatas[0].frontmatter.title}>
    <React.Fragment>
        <GlobalStyle />
    </React.Fragment>
    <Seo title={leafDatas[0].frontmatter.title} />
    
    <section className={`${ttPage.topTitle}`}>
      <div className={`${ttPage.topTitleFrame}`}>
        <h1>{leafDatas[0].frontmatter.title}</h1>

        <div className={`${style.startDateFrame} onProgram`}>
          <span>{topData.frontmatter.eventYear}</span>
          <span>{topData.frontmatter.eventStartMonth}</span>
          <span>{topData.frontmatter.eventStartDay}</span>
          <span>{topData.frontmatter.eventStartWeek}</span>
          <span>{topData.frontmatter.eventStartTime}</span>

          {isEndDate && (
            <div>
              <span>{topData.frontmatter.eventYear}</span>
              <span>{topData.frontmatter.eventEndMonth}</span>
              <span>{topData.frontmatter.eventEndDay}</span>
              <span>{topData.frontmatter.eventEndWeek}</span>
              <span>{topData.frontmatter.eventEndTime}</span>
            </div>
          )}
        </div>
      </div>
    </section>

    <section className={`${ttPage.timetableFrame}`}  >  
      {timetableDatas.map((item, i) => {

        let cName = item.titleEn.replace(/ /g,'')

          return(
          <div className={`${ttPage.itemFrame} ${cName}`}>
            <div className={`${ttPage.headlineFrame}`}>
              <time>{item.start}〜{item.end}</time>
              <span>{item.titleEn}</span>
            </div>
            <h2 dangerouslySetInnerHTML={{ __html: item.titleJp }} />

            <div className={`${ttPage.speakerFrame} timetable-speaker-frame`}>
            {item.speakers.map((s) => {
              let speaker = speakerDatas.find((v) => v.id === s)
              let id = (speaker) ? speaker.id : ''
              let isSpeaker = (speaker) ? true:false
              return(
                <div className={`${ttPage.speaker} ${id}`}>
                {isSpeaker && (
                  <div>
                    <figure><Image filename={`${speaker.image}`} alt={`${speaker.name}`} /></figure>
                    <span>{speaker.name}</span>
                    <span>{speaker.profileText}</span>
                  </div>
                )}
                </div>
              )
            })}
            </div>
                  {/* <Link to={`../${item.data.slug}`}>詳しくはこちら</Link> */}
              
          </div>
          )
      })}
    </section>

    {/* <a href={`${topData.frontmatter.subscriptionURL}`} target="_blank" rel="noreferrer" className={`${style.buttonFrame}`}>お申し込みはこちら</a> */}

    <script type="application/ld+json">
      {JSON.stringify(schemaOrgJSONLD)}
    </script>

  </Layout>
  )
}

export default IndexPage
